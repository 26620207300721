import React, { FunctionComponent } from "react";
import {
    Badge,
    Box,
    Button,
    HStack,
    Icon,
    Text,
    Flex,
    VStack,
} from "@chakra-ui/react";
import { AiOutlineDislike, AiOutlineLike } from "react-icons/ai";

type Props = {
    description: string;
};

export const StrategyCard: FunctionComponent<Props> = ({ description, feedback = {}, onClickGiveFeedback }) => {

    return (
        <VStack
            fontWeight={"400"}
            fontSize={"14px"}
            color={"#5F6368"}
            borderRadius={"16px"}
            border={"1px solid #E1ECFF"}
            p={"14px 20px"}
            bg={"#fff"}
            maxW={"100%"}
            h={"100%"}
        >
            <Text>{description}</Text>
            <HStack spacing={"19px"} w={"100%"} justify={"right"}>
                {/* <HStack spacing={"19px"}>
                    <Badge
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        minWidth={"20px"}
                        minHeight={"20px"}
                        borderRadius={"40px"}
                        variant="solid"
                        color={"#000"}
                        fontWeight={"900"}
                        fontSize={"8px"}
                        backgroundColor={"rgba(19, 106, 255, 0.20)"}
                    >
                        1
                    </Badge>
                    <Button
                        w={"66px"}
                        h={"20px"}
                        fontSize={"8px"}
                        fontWeight={"500"}
                        borderRadius={"16px"}
                        borderColor={"#E1ECFF"}
                        color={"#032E59"}
                        variant="outline"
                    >
                        Resources
                    </Button>
                </HStack> */}

                { onClickGiveFeedback && (
                    <HStack>
                        <Text fontSize={"12px"}>Was this helpful?</Text>

                        <Box
                            bg={feedback?.rating === "like" ? "#136AFF" : "#E1ECFF"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            w={"21px"}
                            h={"21px"}
                            borderRadius={"50%"}
                            _hover={{
                                cursor: "pointer"
                            }}
                            onClick={() => onClickGiveFeedback('like')}
                        >
                            <Icon
                                color={feedback?.rating === "like" ? "#E1ECFF" : "#136AFF"}
                                fontSize={"13px"}
                                as={AiOutlineLike}
                            />
                        </Box>

                        <Box
                            bg={feedback?.rating === "dislike" ? "#136AFF" : "#E1ECFF"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            w={"21px"}
                            h={"21px"}
                            borderRadius={"50%"}
                            _hover={{
                                cursor: "pointer"
                            }}
                            onClick={() => onClickGiveFeedback('dislike')}
                        >
                            <Icon
                                color={feedback?.rating === "dislike" ? "#E1ECFF" : "#136AFF"}
                                fontSize={"13px"}
                                as={AiOutlineDislike}
                            />
                        </Box>
                    </HStack>
                )}
            </HStack>
        </VStack>
    );
};
