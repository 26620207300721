import React, { FunctionComponent, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardBodyProps,
    CardFooter,
    CardHeader,
    CardProps,
    Heading,
    HeadingProps,
    Text,
} from "@chakra-ui/react";
import { FocusStyles } from "../../styles";
import { RellieSelect } from "../common/RellieSelect";
import { MdArrowDropDown } from "react-icons/md";

type Props = {
    onStartCallback: (value: string) => void;
};

export const Environment: FunctionComponent<Props> = ({
    onStartCallback,
}: Props) => {
    const [currentEnvironment, setCurrentEnvironment] = useState(null);

    return (
        <>
            <Heading {...(FocusStyles.Heading as HeadingProps)}>
                Screening Questionnaire
            </Heading>
            <Card {...(FocusStyles.Card as CardProps)}>
                <CardHeader {...FocusStyles.BodySection}>
                    <Heading {...FocusStyles.CardHeading}>
                        Welcome to the Rellie Screening Questionnaire
                    </Heading>
                    <Text {...FocusStyles.CardSummary}>
                        Before we get started, we just want to know a little bit
                        about the environment you are in. It is very important
                        that you are NOT in a busy, noisy or distracting
                        environment. Please select the most appropriate scenario
                        from the dropdown below.
                    </Text>
                </CardHeader>
                <CardBody {...(FocusStyles.BodySection as CardBodyProps)}>
                    <Heading {...FocusStyles.CardHeading}>
                        What is your current environment?
                    </Heading>
                    <RellieSelect
                        placeholder={"Select one"}
                        options={[
                            {
                                label: "I am in the classroom with my teacher and classmates",
                                value: "IN_CLASS",
                            },
                            {
                                label: `I am at school in a one-on-one environment with: my teacher \n/ after school program / resource teacher`,
                                value: "TEACHER_ONE_ON_ONE",
                            },
                            {
                                label: "I am at home in a quiet setting with my parents supporting me from the next room",
                                value: "AT_HOME",
                            },
                            {
                                label: "I am a homeschooled student",
                                value: "HOMESCHOOLED",
                            },
                        ]}
                        value={currentEnvironment}
                        onChangeHandler={(value) => {
                            setCurrentEnvironment(value);
                        }}
                        extraPropsForMenuButton={{
                            borderRadius: "50px",
                            _hover: {
                                border: "1px solid #136AFF",
                                borderBottom: "1px solid #136AFF",
                            },
                            _expanded: {
                                border: "1px solid #136AFF",
                                borderBottom: "1px solid #136AFF",
                            },
                            w: "700px",
                            minHeight: "45px",
                            border: "0.5px solid rgba(0, 0, 0, 0.19)",
                            borderBottom: "0.5px solid rgba(0, 0, 0, 0.19)",
                            textAlign: "center",
                            rightIcon: (
                                <MdArrowDropDown size={40} fill={"#136AFF"} />
                            ),
                            sx: {
                                " & > span > pre": {
                                    marginRight: "-55px",
                                },
                            },
                        }}
                        extraPropsForMenuList={{
                            textAlign: "center",
                            width: "700px",
                            sx: {
                                " & > button ": {
                                    textAlign: "center",
                                    width: "100%",
                                    justifyContent: "center",
                                },
                            },
                        }}
                    />

                    <Text
                        mt={"12px"}
                        color={"#136AFF"}
                        fontSize={"14px"}
                        fontWeight={700}
                    >
                        Please note, parents should not influence the answers,
                        only support by clarifying any questions
                    </Text>
                </CardBody>
                <CardFooter
                    {...FocusStyles.BodySection}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <Button
                        isDisabled={!currentEnvironment}
                        {...FocusStyles.Button}
                        bg="rgba(19, 106, 255, 1)"
                        onClick={() => onStartCallback(currentEnvironment)}
                    >
                        NEXT
                    </Button>
                </CardFooter>
            </Card>
        </>
    );
};
