import React, { FunctionComponent, useEffect } from "react";
import {
    HStack,
    VStack,
    Box,
    Text,
    useDisclosure,
    FormErrorMessage,
    FormControl,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Textarea,
    chakra,
} from "@chakra-ui/react";
import * as z from "zod";
import { InputForm } from "../common/InputForm";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { BsPlusCircleFill } from "react-icons/bs";

export const StrategyFeedbackModal: FunctionComponent = ({ isOpen, onClose, onSubmit, defaultValues }) => {

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({
        resolver: zodResolver(z.object({
            feedback: z.string().max(1000, { message: "Please keep your feedback to less than 1,000 characters!" }).optional(),
        })),
        shouldUnregister: false,
    });

    useEffect(() => {
        setValue('feedback', defaultValues.feedback || '')
    }, [defaultValues])

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW="524px">
                    <ModalHeader
                        mt="14px"
                        fontFamily="Nunito"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="20px"
                        color="#032E59"
                    >
                        Feedback
                        <Text
                            fontFamily="Nunito"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="24px"
                        >
                            Please let us know what you liked or disliked about this strategy.
                        </Text>
                    </ModalHeader>
                    <chakra.form onSubmit={handleSubmit((data) => {
                            onSubmit({
                                ...defaultValues,
                                ...data
                            })
                        })}>
                        <ModalBody>
                            <VStack w="100%">
                                <HStack w="100%" gap="20px" minHeight="75px">

                                    <FormControl isInvalid={Boolean(errors?.feedback?.message)}>
                                        <VStack>
                                            <Textarea
                                                {...register("feedback")}
                                                name={"feedback"}
                                                placeholder={"Start typing your message (1,000 characters max) ..."}
                                                rows={5}
                                                // height={"30px"}
                                                width={"100%"}
                                                error={
                                                    errors?.feedback?.message as
                                                        | string
                                                        | undefined
                                                }
                                            />
                                            <FormErrorMessage>{errors?.feedback?.message || ''}</FormErrorMessage>
                                        </VStack>
                                    </FormControl>
                                
                                </HStack>
                            </VStack>
                        </ModalBody>
                        <ModalFooter justifyContent="center">
                            <Button
                                w="100px"
                                h="30px"
                                bg="#fff"
                                border="1px solid #ACCBFF"
                                borderRadius="37px"
                                color="#032E59"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="16px"
                                mr="10px"
                                onClick={onClose}
                            >
                                Close
                            </Button>
                            <Button
                                w="100px"
                                h="30px"
                                bg="#136AFF"
                                borderRadius="37px"
                                color="#fff"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="16px"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </ModalFooter>
                    </chakra.form>
                </ModalContent>
            </Modal>
        </>
    );
};
