import React, { FunctionComponent, useEffect, useState } from "react";
import {
    Box,
    Heading,
    HStack,
    VStack,
    Text,
    Grid,
    Tabs,
    Tab,
    TabList,
    TabPanels,
    TabPanel,
    GridItem,
} from "@chakra-ui/react";
import { CircleProgress } from "./common/CircleProgress";
import {
    handleColorPercentage,
    handleTextPercentage,
} from "../pages/Teacher/TeacherClassReport";
import { StrategyCard } from "./common/StrategyCard";
import { getColor } from "../app";


type Props = {
    selectedCognitiveAreaId: number;
    cognitiveId: number;
    title: string;
    percentage?: number;
    strategies: {
        label: string;
        description: string;
        level: number;
    }[];
    colorScheme: string;
};

export const StrategyReportElement: FunctionComponent<Props> = ({
    selectedCognitiveAreaId,
    cognitiveId,
    title,
    percentage,
    strategies,
    colorScheme,
    onProvideStrategyFeedback,
}) => {
    const [selectedLevel, setSelectedLevel] = useState(0);

    percentage = Math.round(percentage)

    useEffect(() => {
        if (selectedLevel) {
            setSelectedLevel(0);
        }
    }, [selectedCognitiveAreaId]);

    const levels = {};

    strategies.forEach((strategy) => {
        levels[strategy.level] = true;
    });


    return (
        <>
            <VStack
                bg={"rgba(19, 106, 255, 0.03)"}
                borderRadius={"20px"}
                padding={"20px 15px"}
                maxW={"100%"}
                w={"100%"}
                alignItems={"flex-start"}
                position={"relative"}
            >
                <Box
                    position={"absolute"}
                    left={"-3px"}
                    w="5px"
                    h={"90%"}
                    backgroundColor={getColor(colorScheme)}
                />

                <Heading
                    id={`strategy-${cognitiveId}`}
                    color={"#032E59"}
                    fontWeight={"700"}
                    size={"sm"}
                >
                    {title}
                </Heading>
                <HStack spacing={"16px"} w={"100%"} maxW={"100%"}>
                    <Tabs
                        w={"100%"}
                        onChange={(index) => setSelectedLevel(index)}
                        index={selectedLevel}
                    >
                        <TabList>
                            {Object.keys(levels).map((level) => (
                                <Tab key={level} color={"#136AFF"}>
                                    <Text color={"#032E59"}>Tier {level}</Text>
                                </Tab>
                            ))}
                            <Tab color={"#136AFF"}>
                                <Text color={"#032E59"}>All</Text>
                            </Tab>
                        </TabList>

                        <TabPanels>
                            {Object.keys(levels).map((level) => (
                                <TabPanel key={level}>

                                    <HStack w={"100%"} justifyContent={"space-between"} spacing='40px' alignItems="start">

                                        {percentage && (
                                            <Box
                                                bg={"#FFFFFF"}
                                                h={"141px"}
                                                w={"172px"}
                                                borderRadius={"16px"}
                                                border={"1px solid #E1ECFF"}
                                                display={"flex"}
                                                flexDirection={"column"}
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                            >
                                                <CircleProgress
                                                    color={handleColorPercentage(
                                                        percentage,
                                                        false,
                                                    )}
                                                    value={+percentage}
                                                >
                                                    {`${percentage}%`}
                                                </CircleProgress>
                                                <Text fontSize={"8px"}>
                                                    {handleTextPercentage(
                                                        percentage,
                                                    )}
                                                </Text>
                                            </Box>
                                        )}

                                        <Grid
                                            gap="16px"
                                            gridTemplateColumns="repeat( auto-fit, minmax(275px, 1fr))"
                                            w={"100%"}
                                            mt="15px"
                                        >
                                            {strategies.length > 0 ? (
                                                strategies.map((strategy, i) => {
                                                    if (
                                                        strategy.level !==
                                                        parseInt(level)
                                                    ) {
                                                        return null;
                                                    }
                                                    return (
                                                        <GridItem key={i} maxW={"276px"}>
                                                            <StrategyCard
                                                                key={`${strategy.label}${i}`}
                                                                description={
                                                                    strategy.description
                                                                }
                                                                feedback={strategy.feedback}
                                                                onClickGiveFeedback={(type) => {
                                                                    onProvideStrategyFeedback(strategy.Id_Strategy, type)
                                                                }}
                                                            />
                                                        </GridItem>
                                                    );
                                                })
                                            ) : (
                                                <VStack
                                                    bg={"#FFFFFF"}
                                                    spacing={"4.6px"}
                                                    borderRadius={"16px"}
                                                    border={"1px solid #E1ECFF"}
                                                    h={"141px"}
                                                    padding={"18px"}
                                                >
                                                    <Text>
                                                        No intervention required.
                                                    </Text>
                                                </VStack>
                                            )}
                                        </Grid>

                                    </HStack>
                                </TabPanel>
                            ))}
                            <TabPanel>

                                <HStack w={"100%"} justifyContent={"space-between"} spacing='40px' alignItems="start">

                                    {percentage && (
                                        <Box
                                            bg={"#FFFFFF"}
                                            h={"141px"}
                                            w={"172px"}
                                            borderRadius={"16px"}
                                            border={"1px solid #E1ECFF"}
                                            display={"flex"}
                                            flexDirection={"column"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                        >
                                            <CircleProgress
                                                color={handleColorPercentage(
                                                    percentage,
                                                    false,
                                                )}
                                                value={+percentage}
                                            >
                                                {`${percentage}%`}
                                            </CircleProgress>
                                            <Text fontSize={"8px"}>
                                                {handleTextPercentage(percentage)}
                                            </Text>
                                        </Box>
                                    )}
                                
                                    <Grid
                                        gap="16px"
                                        gridTemplateColumns="repeat( auto-fit, minmax(275px, 1fr))"
                                        w={"100%"}
                                        mt="15px"
                                    >
                                        {strategies.length > 0 ? (
                                            strategies.map((strategy, i) => (
                                                <GridItem key={i} maxW={"276px"}>
                                                    <StrategyCard
                                                        key={`${strategy.label}${i}`}
                                                        description={
                                                            strategy.description
                                                        }
                                                    />
                                                </GridItem>
                                            ))
                                        ) : (
                                            <VStack
                                                bg={"#FFFFFF"}
                                                spacing={"4.6px"}
                                                borderRadius={"16px"}
                                                border={"1px solid #E1ECFF"}
                                                h={"141px"}
                                                padding={"18px"}
                                            >
                                                <Text>No intervention required.</Text>
                                            </VStack>
                                        )}
                                    </Grid>
                                </HStack>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </HStack>
            </VStack>
        </>
    );
};
