import { useState, useEffect } from "react";
import { useQuery, useMutation as useQueryMutation, useQueryClient } from "@tanstack/react-query";
import { get } from "lodash";


export const webappOrigin = window.location.origin;

// @source https://stackoverflow.com/a/39008859
async function injectScript(src) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.addEventListener("load", resolve);
        script.addEventListener("error", (e) => reject(e.error));
        document.head.appendChild(script);
    });
}

export let fetch = null;
export let api = null;
export let formatRequestUrl = null;
export let logError = (error) => {

    if (!error.time) error.time = Date.now()

    // Until the error logger is attached we store errors in local storage and flush them once
    // the error logger is attached.
    const errors = JSON.parse(window.localStorage.getItem("re-errors") || "[]")
    if (errors.length < 10) {
        const errorKey = `${error.type}:${error.code}:${error.pageUrl}:${error.message}:${Math.floor(error.time/(5 * 1000))}`
        let errorExists = false
        for (const existingError of errors) {
            if (existingError.errorKey === errorKey) {
                errorExists = true
                break
            }
        }
        if (!errorExists) {
            errors.push({
                errorKey,
                ...error
            })
            window.localStorage.setItem("re-errors", JSON.stringify(errors))
        }
    }
}


async function runQuery (resourceName, args = {}) {
    const queryApi = get(api, resourceName.split(".").concat("Get"));
    if (!queryApi) {
        throw new Error(
            `Queryable resource with name '${resourceName}.Get' not found on 'api'!`,
        );
    }

    return await queryApi.query(args).catch((err) => {
        if (
            err.name === "TRPCClientError" &&
            err.data?.code === "UNAUTHORIZED"
        ) {
            return {};
        }
        throw err;
    });
}

export const useData = (resourceName, args = null) => {

    const [refreshTrigger, setRefreshTrigger] = useState(0);

    const queryClient = useQueryClient();

    const loader = useQuery({
        queryKey: [resourceName].concat((!!args && [args]) || []),
        queryFn: async () => {
            return runQuery(resourceName, (!!args && args) || undefined);
        }
    });

    return loader;
};

export const useSave = (resourceName, { loadAfterMutation = true } = {}) => {

    let activeMutationDeferred = null;
    const queryClient = useQueryClient();
    // const resourceData = useData(resourceName);
    const mutation = useQueryMutation(
        async (input: string) => {

            const queryApi = get(api, resourceName.split(".").concat("Save"));
            if (!queryApi) {
                throw new Error(
                    `Mutatable resource with name '${resourceName}.Save' not found on 'api'!`,
                );
            }

            await queryApi.mutate(input).catch((err) => {
                if (
                    err.name === "TRPCClientError" &&
                    err.data?.code === "UNAUTHORIZED"
                ) {
                    return {};
                }
                throw err;
            });
        },
        {
            onSuccess: async () => {
                let data
                if (loadAfterMutation === true) {
                    data = await runQuery(resourceName);
                    queryClient.setQueryData([resourceName], data);
                }
                if (activeMutationDeferred) {
                    activeMutationDeferred.resolve(data);
                    activeMutationDeferred = null;
                }
            },
        },
    );

    mutation.save = async (input) => {
        if (activeMutationDeferred) {
            throw new Error(`A mutation for resource '${resourceName}' is already active!`);
        }
        activeMutationDeferred = {};
        activeMutationDeferred.promise = new Promise(async (resolve) => {
            activeMutationDeferred.resolve = resolve;
        });
        await mutation.mutate(input);
        return activeMutationDeferred.promise;
    }

    return mutation;
};

export const API_BACKEND_ENVIRONMENTS = {
    'automatic': 'automatic',
    'localhost': 'http://localhost:3000',
    // 'staging': 'https://pal-api-stage.rellie.dev',
    // 'production': 'https://pal-api-prod.rellie.dev',
    // 'preview': 'https://pal-api-pre.rellie.dev',
    'preview': 'https://pal-pre.rellie.dev/api/pre',
    'staging': 'https://pal.rellie.dev/api/stage',     // NOTE: We proxy the API through the web domain to avoid the OPTIONS request
    'production': 'https://pal.rellie.com/api/prod',   // NOTE: We proxy the API through the web domain to avoid the OPTIONS request
};

const WEB_ENVIRONMENTS = {
    'http://localhost:8000': 'staging',
    'https://pal-pre.rellie.dev': 'preview',
    'https://pal.rellie.dev': 'staging',
    'https://pal.rellie.com': 'production'
}
if (/-rellie-ai\.vercel\.app$/.test(window.location.origin)) {
    WEB_ENVIRONMENTS[window.location.origin] = 'preview';
}

function getCurentApiBackendEnvironment () {
    let currentApiBackendEnvironment = window.localStorage.getItem("re-api-backend-environment");
    if (!currentApiBackendEnvironment) {
        // if (
        //     typeof process.env.VERCEL_GIT_COMMIT_REF !== 'undefined' &&
        //     process.env.VERCEL_GIT_COMMIT_REF !== 'master' &&
        //     typeof process.env.VERCEL_GIT_COMMIT_AUTHOR_NAME !== 'undefined' &&
        //     process.env.VERCEL_GIT_COMMIT_AUTHOR_NAME === 'Christoph Dorn'
        // ) {
        //     currentApiBackendEnvironment = 'preview';
        // } else {
        //     currentApiBackendEnvironment = 'staging';
        // }
        currentApiBackendEnvironment = 'automatic';
        window.localStorage.setItem("re-api-backend-environment", currentApiBackendEnvironment);
    }

    let backendBaseurl = '';

    if (currentApiBackendEnvironment === 'automatic') {
        const env = WEB_ENVIRONMENTS[window.location.origin] || null;
        if (!env) {
            throw new Error(`Could not determine WEB_ENVIRONMENTS for origin '${window.location.origin}'!`);
        }
        backendBaseurl = API_BACKEND_ENVIRONMENTS[env];
    } else {
        backendBaseurl = API_BACKEND_ENVIRONMENTS[currentApiBackendEnvironment];
    }

    if (window.localStorage.getItem("re-api-backend-baseurl") !== backendBaseurl) {
        window.localStorage.setItem("re-api-backend-baseurl", backendBaseurl);
    }

    return {
        currentApiBackendEnvironment,
        currentApiBackendEnvironmentBaseurl: backendBaseurl
    };
}
// Set Local Storage records on page load.
getCurentApiBackendEnvironment();

export function getEnvironment () {
    return WEB_ENVIRONMENTS[window.location.origin];
}

export function useApiBackend () {
    const { currentApiBackendEnvironment } = getCurentApiBackendEnvironment();
    const [selectedApiBackendEnvironment, setSelectedApiBackendEnvironment] = useState(currentApiBackendEnvironment);
    if (selectedApiBackendEnvironment !== currentApiBackendEnvironment) {
        window.localStorage.setItem("re-api-backend-environment", selectedApiBackendEnvironment);
        getCurentApiBackendEnvironment();
        window.location.reload();
    }
    return [selectedApiBackendEnvironment, setSelectedApiBackendEnvironment] as const;
}

export function getUnlockPreviewToken () {
    return window.localStorage.getItem('re-unlock-preview-token');
}

const apiReadyPromise = (async function () {
    try {
        const { currentApiBackendEnvironment, currentApiBackendEnvironmentBaseurl } = getCurentApiBackendEnvironment();
        let url = `${currentApiBackendEnvironmentBaseurl}/api/client/client-browser.js`;

        const unlockPreviewToken = getUnlockPreviewToken();
        if (unlockPreviewToken) {
            url = `${url}?re-unlock-preview-token=${unlockPreviewToken}`;
        }

        await injectScript(url);

        if (typeof window._re_locked_preview !== "undefined" && window._re_locked_preview === true) {

            if (!unlockPreviewToken && window.location.host === 'localhost:8000') {
                // We are in local development and need to unlock for the first time.
                // We unlock the localhost domain and set the unlock cookie on preview.

                // Try and get token from local system
                // NOTE: This also sets the preview token on preview (when getting the bookmarklet).
                const getTokenUrl = '//localhost:3000/api/admin/preview/token'
                window.fetch(getTokenUrl, {
                    cache: "no-cache",
                }).then((response) => {
                    response.text().then((token) => {

                        const stageBookmarkletUrl = `${API_BACKEND_ENVIRONMENTS.preview}/api/admin/preview/bookmarklet?mode=html&token=${token}`
                        console.log(`Injecting preview bookmarklet for PREVIEW using:`, stageBookmarkletUrl)
                        const handle = window.open(stageBookmarkletUrl, "_blank")
                        if (handle) {
                            const localBookmarkletUrl = `//localhost:3000/api/admin/preview/bookmarklet?token=${token}`
                            console.log(`Injecting preview bookmarklet for LOCALHOST using:`, localBookmarkletUrl)
                            injectScript(localBookmarkletUrl);
                        } else {
                            alert('You need to enable pop-ups for this domain!')
                        }

                    }, (err) => {
                        console.error(`ERROR getting text after loading preview token from ${getTokenUrl}!`, err)
                    })
                }, (err) => {
                    console.error(`ERROR loading preview token from ${getTokenUrl}! Is the local API server running?`, err)
                })
            }

            const err = new Error(
                `This Rellie Preview is locked! Use the 'Unlock Rellie Previews' bookmarklet to gain access to this preview.`
            );
            err.code = 'LOCKED_PREVIEW';
            throw err;
        }

        if (typeof window._re_reports_backend === "undefined") {
            throw new Error(
                `'window._re_reports_backend' not set after trying to load '${url}'!`
            );
        }
        if (typeof window._re_reports_backend.api === "undefined") {
            throw new Error(
                `'window._re_reports_backend.api' not set after trying to load '${url}'!`
            );
        }
        if (typeof window._re_reports_backend.formatRequestUrl === "undefined") {
            throw new Error(
                `'window._re_reports_backend.formatRequestUrl' not set after trying to load '${url}'!`
            );
        }
        if (typeof window._re_reports_backend.logError === "undefined") {
            throw new Error(
                `'window._re_reports_backend.logError' not set after trying to load '${url}'!`
            );
        }

        api = window._re_reports_backend.api;
        fetch = window._re_reports_backend.fetch;
        formatRequestUrl = window._re_reports_backend.formatRequestUrl;
        logError = window._re_reports_backend.logError;
    } catch (err) {
        return Promise.reject(err);
    }
})();

export function useApiReady(): boolean | { error: string } {
    const [ready, setReady] = useState<boolean | { error: string }>(false);
    useEffect(() => {
        if (ready) return;
        apiReadyPromise
            .then(() => {
                setReady(true);
            })
            .catch((err) => {
                if (err && err.code === 'LOCKED_PREVIEW') {
                    setReady({
                        error: {
                            code: 'LOCKED_PREVIEW',
                            message: err.message
                        }
                    });
                } else {
                    console.error('Error loading backend API:', err);
                    const { currentApiBackendEnvironment, currentApiBackendEnvironmentBaseurl } = getCurentApiBackendEnvironment();
                    console.error(`NOTE: You are trying to load the '${currentApiBackendEnvironment}' backend API from '${currentApiBackendEnvironmentBaseurl}' but it is not working. The backend API may not be running for this environment. To recover and connect to a different backend, remove the 're-api-backend-environment' Local Storage value and reload the page.`);
                    setReady({
                        error: {
                            code: "ERROR_LOADING_APPLICATION_BACKEND_API"
                        }
                    });
                }
            });
    });
    return ready;
}
