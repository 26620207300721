import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import {
    Heading,
    VStack,
    HStack,
    Button,
    Box,
    Text,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { useApp } from "../../../lib/app/App";

export const AdminErrorsPage: FunctionComponent<
    RouteComponentProps
> = () => {

    const { useData, api } = useApp();

    const errorRecords = useData("App.Errors");

    async function onDeleteRecord (Id) {

        await api.App.Errors.Delete.mutate({
            Id
        });

        errorRecords.refetch();
    }

    async function onDeleteAllRecords (Id) {

        await api.App.Errors.DeleteAll.mutate({
            Id
        });

        errorRecords.refetch();
    }

    if (!errorRecords.data) {
        return null;
    }

    return (
        <VStack alignItems={"flex-start"} spacing={"52px"}>
            <Box marginTop={"50px;"}>
                <Heading size={"md"} color={"#032E59"}>
                    Errors
                </Heading>

                <VStack marginTop={"50px"} spacing="25px">

                    <TableContainer mt={"18px"}>
                        <Table
                            variant="simple"
                            bg={"#FFFFFF"}
                            borderRadius={"16px"}
                            size="sm"
                            sx={{
                                "& th": {
                                    color: "#5F6368",
                                    fontSize: "10px",
                                    textTransform: "capitalize",
                                },
                                "& td": {
                                    fontSize: "12px",
                                },
                            }}
                        >
                            <Thead>
                                <Tr>
                                    <Th
                                        position={"sticky"}
                                        top={0}
                                        backgroundColor={"#ffffff"}
                                    >Id</Th>
                                    <Th
                                        position={"sticky"}
                                        top={0}
                                        backgroundColor={"#ffffff"}
                                    >Time</Th>
                                    <Th
                                        position={"sticky"}
                                        top={0}
                                        backgroundColor={"#ffffff"}
                                    >Client Time</Th>
                                    <Th
                                        position={"sticky"}
                                        top={0}
                                        backgroundColor={"#ffffff"}
                                    >Runtime</Th>
                                    <Th
                                        position={"sticky"}
                                        top={0}
                                        backgroundColor={"#ffffff"}
                                    >User</Th>
                                    <Th
                                        position={"sticky"}
                                        top={0}
                                        backgroundColor={"#ffffff"}
                                    >Role</Th>
                                    <Th
                                        position={"sticky"}
                                        top={0}
                                        backgroundColor={"#ffffff"}
                                    >Type</Th>
                                    <Th
                                        position={"sticky"}
                                        top={0}
                                        backgroundColor={"#ffffff"}
                                    >Error Code</Th>
                                    <Th
                                        position={"sticky"}
                                        top={0}
                                        backgroundColor={"#ffffff"}
                                    >Message</Th>
                                    <Th
                                        position={"sticky"}
                                        top={0}
                                        backgroundColor={"#ffffff"}
                                    >Page URL</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {errorRecords.data.map((record) => (
                                    <Tr
                                        key={record.Id}
                                        data-id={record.Id}
                                        onClick={(event) => {
                                            const trEl = event.target.closest('TR')
                                            const detailTrEl = trEl.parentElement.querySelector(`TR.details_${trEl.dataset.id}`)
                                            trEl.after(detailTrEl)
                                            if (detailTrEl.style.display === 'none') {
                                                detailTrEl.style.display = 'table-row'
                                            } else {
                                                detailTrEl.style.display = 'none'
                                            }
                                        }}
                                    >
                                        <Td>
                                            {record.Id}
                                        </Td>
                                        <Td>
                                            {record.CreatedAt}
                                        </Td>
                                        <Td>
                                            {record.ClientTime}
                                        </Td>
                                        <Td>
                                            {record.OriginEnvironment}
                                        </Td>
                                        <Td>
                                            {record.Id_User} - {record.User?.Email}
                                        </Td>
                                        <Td>
                                            {record.Role}
                                        </Td>
                                        <Td>
                                            {record.Type}
                                        </Td>
                                        <Td>
                                            {record.Code}
                                        </Td>
                                        <Td>
                                            {record.Message?.substring(0, 50)}
                                        </Td>
                                        <Td>
                                            {record.PageURL}
                                        </Td>
                                    </Tr>
                                ))}
                                {errorRecords.data.map((record) => (
                                    <Tr
                                        key={`${record.Id}-details`}
                                        className={`details_${record.Id}`}
                                        style={{
                                            display: "none"
                                        }}
                                    >
                                        <Td colSpan="9">
                                            <VStack gap={"10px"} align="left">

                                                <Box>
                                                <Button size="sm" onClick={() => onDeleteRecord(record.Id)}>Delete</Button>
                                                <Button size="sm" onClick={() => onDeleteAllRecords(record.Id)}>Delete All</Button>
                                                </Box>

                                                <HStack gap={"20px"} align="top">
                                                    <Box style={{
                                                        fontWeight: "bold"
                                                    }}>User Agent:</Box>
                                                    <Box style={{
                                                        whiteSpace: "normal"
                                                    }}>{record.UserAgent}</Box>
                                                </HStack>

                                                <HStack gap={"20px"} align="top">
                                                    <Box style={{
                                                        fontWeight: "bold"
                                                    }}>Message:</Box>
                                                    <Box ml="50px" style={{
                                                        whiteSpace: "normal"
                                                    }}>{record.Message}</Box>
                                                </HStack>

                                                <HStack gap={"20px"} align="top">
                                                    <Box style={{
                                                        fontWeight: "bold"
                                                    }}>API:</Box>
                                                    <Box style={{
                                                        whiteSpace: "normal"
                                                    }}>{JSON.stringify(record.Api, null, 4)}</Box>
                                                    <Box style={{
                                                        fontWeight: "bold"
                                                    }}>Stack:</Box>
                                                    <Box style={{
                                                        whiteSpace: "normal"
                                                    }}>{(record.Stack || '').split('\n').map((line, i) => (
                                                        <Text key={i}>{line}</Text>
                                                    ))}</Box>
                                                </HStack>

                                                <HStack gap={"20px"} align="top">
                                                    <Box style={{
                                                        fontWeight: "bold"
                                                    }}>Filename:</Box>
                                                    <Box style={{
                                                        whiteSpace: "normal"
                                                    }}>{record.Filename}</Box>
                                                    <Box style={{
                                                        fontWeight: "bold"
                                                    }}>Line:</Box>
                                                    <Box style={{
                                                        whiteSpace: "normal"
                                                    }}>{record.Lineno}</Box>
                                                    <Box style={{
                                                        fontWeight: "bold"
                                                    }}>Column:</Box>
                                                    <Box style={{
                                                        whiteSpace: "normal"
                                                    }}>{record.Colno}</Box>
                                                </HStack>

                                                <HStack gap={"20px"} align="top">
                                                    <Box style={{
                                                        fontWeight: "bold"
                                                    }}>Cookies:</Box>
                                                    <Box style={{
                                                        whiteSpace: "normal",
                                                        maxWidth: "500px"
                                                    }}>{JSON.stringify(record.Cookies, null, 4)}</Box>
                                                    <Box style={{
                                                        fontWeight: "bold"
                                                    }}>Headers:</Box>
                                                    <Box style={{
                                                        whiteSpace: "normal",
                                                        maxWidth: "500px"
                                                    }}>{JSON.stringify(record.Headers, null, 4)}</Box>
                                                </HStack>

                                            </VStack>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>

                </VStack>
            </Box>
        </VStack>
    );
};
