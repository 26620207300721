import React, { FunctionComponent } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { Heading, Text, VStack, Box, Icon, Stack } from "@chakra-ui/react";
import { BsPerson } from "react-icons/bs";
import { MdOutlineAssignment } from "react-icons/md";
import { Loading } from "../../components/common/Loading";

import { useApp } from "../../../lib/app/App";

export const StudentDashboardPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const { useData } = useApp();
    const userData = useData("User");
    const questionnaireData = useData("Assessment.Questionnaire");

    if (!userData.isFetched || !questionnaireData.isFetched || !userData.data || !questionnaireData.data) {
        return <Loading />;
    }

    return (
        <VStack alignItems={"flex-start"} spacing={"15px"}>
            <Box marginTop="20px">
                <Heading size={"md"} color={"#032E59"}>
                    Hi {userData.data?.user?.FirstName},
                </Heading>
                <Text color={"#032E59"}>Here’s your Rellie overview. </Text>
            </Box>

            <VStack
                bg={"#FFFFFF"}
                borderRadius={"20px"}
                spacing={"50px"}
                p={"20px 20px 80px 20px"}
                alignSelf={"center"}
            >
                <Stack
                    direction={{ base: "column", lg: "row" }}
                    spacing={"12.5px"}
                >
                    <VStack
                        w={"255px"}
                        h={"225px"}
                        bg={
                            "linear-gradient(0deg, rgba(28, 207, 140, 0.22), rgba(28, 207, 140, 0.22)), #FFFFFF"
                        }
                        borderRadius={"16px"}
                        justifyContent={"center"}
                        cursor={"pointer"}
                        onClick={() => navigate("/student/profile")}
                    >
                        <Icon
                            as={MdOutlineAssignment}
                            fontSize={"50px"}
                            color={"#1CCF8C"}
                        />
                        <Text
                            color={"#032E59"}
                            fontWeight={"700"}
                            fontSize={"23px"}
                            lineHeight={"20px"}
                        >
                            PROFILE
                        </Text>
                    </VStack>
                    {questionnaireData.data && (
                        <VStack
                            w={"255px"}
                            h={"225px"}
                            bg={
                                "linear-gradient(0deg, rgba(255, 77, 209, 0.2), rgba(255, 77, 209, 0.2)), #FFFFFF"
                            }
                            borderRadius={"16px"}
                            justifyContent={"center"}
                            cursor={"pointer"}
                            onClick={() =>
                                navigate(
                                    `/assessment/${questionnaireData.data.Id_Assessment}/questionnaire`,
                                )
                            }
                        >
                            <Icon
                                as={BsPerson}
                                fontSize={"50px"}
                                color={"#FF4DD1"}
                            />
                            <Text
                                color={"#032E59"}
                                fontWeight={"700"}
                                fontSize={"23px"}
                                lineHeight={"20px"}
                            >
                                QUESTIONNAIRE
                            </Text>

                            {questionnaireData.data?.complete === true && (
                                <Text
                                    color={"#00FF00"}
                                    fontWeight={"700"}
                                    fontSize={"23px"}
                                    lineHeight={"20px"}
                                >
                                    COMPLETE
                                </Text>
                            )}
                            {questionnaireData.data?.complete === false && (
                                <Text
                                    color={"#FF0000"}
                                    fontWeight={"700"}
                                    fontSize={"23px"}
                                    lineHeight={"20px"}
                                >
                                    INCOMPLETE
                                </Text>
                            )}
                        </VStack>
                    )}
                </Stack>

                <Text
                    fontSize={"20px"}
                    lineHeight={"24px"}
                    fontWeight={"700"}
                    textAlign={"center"}
                >
                    Welcome to the Rellie dashboard.
                </Text>
            </VStack>
        </VStack>
    );
};
