import React, { FunctionComponent } from "react";
import { Spinner, VStack, Text } from "@chakra-ui/react";

import { useApp } from "../../../lib/app/App";
import { ErrorComponent } from "../../pages/Error"


export const Loading: FunctionComponent = ({ error }) => {

    const { logError } = useApp();

    let content = (
        <>
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                w="100px"
                minW="100px"
                height="100px"
                minH="100px"
            />
            <Text position="relative" top="85px" fontFamily="Nunito">
                Loading ...
            </Text>
        </>
    )

    if (error) {

        logError({
            type: 'LoadingError',
            code: error.code,
            message: error.message || '',
        })

        content = ErrorComponent({
            errorCode: error.code,
            ErrorMessage: (
                <>
                    An unexpected error has occurred. Please try again later. If the error
                    persists, please let your Rellie license administrator know.
                </>
            )
        })
    }

    return (
        <VStack
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
        >
            {content}
        </VStack>
    );
};
